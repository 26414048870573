// --- Window config / BE Input ---
import illustrations from '../components/Illustration/components';

export type LayoutT = 'collapsed' | 'narrow' | 'wide' | 'fullscreen';
export type FrameSizeSelector = 'mobile' | 'desktop';

export enum Position {
  TopRight = 'TopRight',
  TopLeft = 'TopLeft',
  BottomRight = 'BottomRight',
  BottomLeft = 'BottomLeft',
  CenterLeft = 'CenterLeft',
  CenterRight = 'CenterRight',
}

export enum Appearance {
  Small = 'Small',
  Large = 'Large',
}

export type Positions = {
  desktop: Position;
  mobile: Position;
};

export type Appearances = {
  desktop: Appearance;
  mobile: Appearance;
};

export type StyleParams = {
  /* Should be valid CSS value e.g. "5px 10px" or "1rem 1rem 0rem 1rem" */
  bodyMargin?: string;
  /* Should be valid CSS value e.g. "5px 10px" or "1rem 1rem 0rem 1rem" */
  bodyPadding?: string;
};

export type AppGroup = 'house-valuation' | 'test-app';

export type WindowIframeApp = {
  type: 'iframe';
  appUrl: string;
  appParams: object;
  analyticsPrefix: string;
  slug: string;
  title: ContentString;
  description: ContentString;
  groups?: Array<AppGroup>;
  illustration?: keyof typeof illustrations;
  icon: { src: string };
  image?: { src: string };
  highlighted?: boolean;
  supportedLang?: Array<Locale>;
  styleParams?: {
    mobile?: StyleParams;
    desktop?: StyleParams;
  };
  weight: number;
  bypassResizer?: boolean;
  pinned?: {
    enabled: boolean;
    weight: number;
  };
  hidden?: boolean;
};

export type WindowApp = WindowIframeApp;

export type FeatureFlag = {
  name: string;
  percentage: number;
};

export type TrackingInfo = {
  url: string;
  key: string;
};

export type AccountFields = {
  name: string;
  url?: string;
  colors?: {
    primary: StyleConfigColorPair;
    secondary: StyleConfigColorPair;
  };
  logo?: {
    s3key: string;
  };
  style?: {
    fontFamily?: string;
  };
};

export type WindowConfig = {
  isDefaultConfig?: boolean;
  accountId: string;
  account: AccountFields;
  googleAnalytics4?: string;
  tracking?: TrackingInfo;
  positions: Positions;
  appearances: Appearances;
  logo: {
    src: string;
  };
  apps: Array<WindowApp>;
  style: StyleConfig;
  zIndex: number;
  featureFlags: Array<FeatureFlag>;
};

export type Locale = 'nl' | 'en';

export type ContentString = Record<Locale, string>;

// --- In App types ---

export type IframeApp = {
  /**
   * The type of the app, dictates what should be used to render the app
   */
  type: 'iframe';

  /**
   * The iframe URL to be loaded
   */
  appUrl: string;

  /**
   * Any additional query parameter that should be set on the appUrl
   * will get query stringified and appended to appURL
   */
  appParams: object;

  /**
   * Slug used for internal paths e.g. /apps/[slug] and lookups of app configurations
   */
  slug: string;

  /**
   * Used to prefix Analytics events fired from within the iframe app window
   */
  analyticsPrefix: string;

  /**
   * The title that will shown in all places where the app is visible`
   */
  title: ContentString;

  /**
   * The description that will shown in all places where the app is visible`
   */
  description: ContentString;

  /*
   * Illustration used everywhere where the app is listed but not in the highlight (deprecated)
   */
  illustration?: keyof typeof illustrations;

  /*
   * Icons used everywhere where the app is listed but not in the highlight
   */
  icon: { src: string };

  /*
   * The image that should be used when the app is highlighted
   */
  image?: { src: string };

  /**
   * Prevent auto resizing
   */
  bypassResizer?: boolean;

  /*
   * Should this app be highlighted, there should only be 1 highlighted app at the same time
   * when we find more than 1 highlighted app, we will use the first ([0])
   */
  highlighted?: boolean;
  supportedLang?: Array<Locale>;
  styleParams?: {
    mobile?: StyleParams;
    desktop?: StyleParams;
  };
  pinned?: {
    enabled: boolean;
    weight: number;
  };

  /**
   * Should this app be "unlisted" in the apps list?
   */
  hidden?: boolean;
};

export type DHApp = IframeApp; // Expand this list when we add more types of apps

export type SharedApp = Pick<WindowApp, 'slug' | 'title'>;

export type StyleConfigColorPair = {
  background: string;
  color: string;
};

export type StyleConfig = {
  success: StyleConfigColorPair;
  error: StyleConfigColorPair;
  primary: StyleConfigColorPair;
  secondary: StyleConfigColorPair;
};

// --- Messages ---

export type HostInfo = {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  origin: string;
  pathname: string;
  port: string;
  protocol: string;
  search: string;
};

export type TriggerType =
  | 'deepLink'
  | 'command'
  | 'headerClick'
  | 'footerClick';

export type OpenMessage = {
  type: 'open';
  payload: {
    path?: string;
    queryParams?: string;
    triggerType?: TriggerType;
  };
};

export type CloseMessage = {
  type: 'close';
  payload: {};
};

export type DismissMessage = {
  type: 'dismiss';
  payload: {};
};

export type ClearDismissMessage = {
  type: 'clearDismiss';
  payload: {};
};

export type ResizeMessage = {
  type: 'resize';
  payload: {
    /**
     * CSS width including the unit
     */
    width: string;
    /**
     * CSS height including the unit
     */
    height: string;

    fullscreen?: boolean;

    /**
     * The position of the widget
     */
    position: Position;

    layout: LayoutT;
    orientation?: OrientationType;
  };
};

export type FrameSizeChangeMessage = {
  type: 'frameSizeChange';
  payload: FrameSizeSelector;
};

export type ConfigLoaded = {
  type: 'configLoaded';
  payload: WindowConfig;
};

export type HostInfoMessage = {
  type: 'hostInfo';
  payload: HostInfo;
};

export type GetHostInfoMessage = {
  type: 'getHostInfo';
};

export type WidgetReadyMessage = {
  type: 'widgetReady';
  payload: {};
};

export type OverwriteConfigMessage = {
  type: 'overwriteConfig';
  payload: Partial<WindowConfig>;
};

export type LocaleMessage = {
  type: 'locale';
  payload: Locale;
};

export type IframeBridgeLoadedMessage = {
  type: 'iframeBridgeLoaded';
};

/**
 * A tracking event that can me send from any embed and reported centralised
 */
export type TrackMessage = {
  type: 'track';
  payload: {
    name: string & {};
    params: { [key: string]: string } & {
      conversion?: boolean;
    };
  };
};

/**
 * Should only ever be called from the error document
 */
export type ErrorDocumentMessage = {
  type: 'errorDocument';
  payload: {};
};

export type PostMessage =
  | ClearDismissMessage
  | CloseMessage
  | ConfigLoaded
  | DismissMessage
  | ErrorDocumentMessage
  | OpenMessage
  | OverwriteConfigMessage
  | ResizeMessage
  | WidgetReadyMessage
  | HostInfoMessage
  | TrackMessage
  | GetHostInfoMessage
  | FrameSizeChangeMessage
  | LocaleMessage
  | IframeBridgeLoadedMessage
  | SendConsentMessage;

// INCOMING ContentWindow Messages
export type ContentWindowApp = {
  slug: string;
  name: {
    nl: string;
    en: string;
  };
};

export type ContentWindowWidgetContext = {
  accountId: string;
  enabledApps: Array<ContentWindowApp>;
};

export type WidgetContextMessage = {
  type: 'emitWidgetContext';
  payload: ContentWindowWidgetContext;
};

export type ConsentString = 'granted' | 'denied';
export type ConsentParams = {
  ad_storage: ConsentString;
  ad_user_data: ConsentString;
  ad_personalization: ConsentString;
  analytics_storage: ConsentString;
};

export type ContentWindowSendConsentMessage = {
  type: 'receiveConsent';
  payload: ConsentParams;
};

export type SendConsentMessage = {
  type: 'sendConsent';
  payload: ConsentParams;
};

export type ContentWindowIngestQueryParamsMessage = {
  type: 'ingestQueryParams';
  payload: Record<string, string>;
};

export type ContentWindowMessage =
  | WidgetContextMessage
  | ContentWindowSendConsentMessage
  | ContentWindowIngestQueryParamsMessage;

// END INCOMING ContentWindow Messages

export type OnMessageHandler = (message: PostMessage) => void;
export type SendMessage = (message: PostMessage) => void;

export type TrafficSource = {
  source: string;
  medium: string;
  campaign: string;
  term: string;
  content: string;
};

export type SessionStartEvent = {
  event: 'SessionStart';
  sessionId: string;
};

export type VirtualPageViewEvent = {
  event: 'VirtualPageView';
  path: string;
};

export type ExpandWidgetEvent = {
  event: 'ExpandWidget';
  triggerType?: TriggerType;
  path: string;
};

export type DeepLinkEvent = {
  event: 'DeepLink';
  triggerType?: TriggerType;
  path: string;
};

export type OpenCommandEvent = {
  event: 'OpenCommand';
  triggerType?: TriggerType;
  path: string;
};

export type CollapseWidgetEvent = {
  event: 'CollapseWidget';
};

export type DismissWidgetEvent = {
  event: 'DismissWidget';
};

export type AppsOpenedEvent = {
  event: 'AppsOpened';
  amount: number;
};

export type TrackMessageEvent = {
  event: 'TrackMessage';
  message: TrackMessage;
};

export type TrackingEvent =
  | CollapseWidgetEvent
  | DismissWidgetEvent
  | ExpandWidgetEvent
  | VirtualPageViewEvent
  | AppsOpenedEvent
  | DeepLinkEvent
  | OpenCommandEvent
  | SessionStartEvent
  | TrackMessageEvent;
