import { prop, sortBy } from 'ramda';
import getPinnedApps from '../getPinnedApps';
import { IWidgetContext } from '../../context/WidgetContext';
import { WindowApp, WindowConfig } from '../../shared/types';

const generateAppsMap = (apps: Array<WindowApp>): IWidgetContext['apps'] =>
  apps.reduce(
    (acc, curr) => {
      // When the key is already set we skip it to respect the sorting order
      if (curr && typeof acc[curr.slug] !== 'undefined') return acc;

      acc[curr.slug] = curr;
      return acc;
    },
    {} as IWidgetContext['apps'],
  );

const sortByWeight = sortBy(prop('weight'));
const sortApps = (apps: Array<WindowApp>): Array<WindowApp> => {
  const { rest } = apps.reduce(
    (acc, app) => {
      const bucketKey = app.pinned && app.pinned.enabled ? 'pinned' : 'rest';
      acc[bucketKey].push(app);

      return acc;
    },
    {
      pinned: [],
      rest: [],
    } as { pinned: Array<WindowApp>; rest: Array<WindowApp> },
  );

  return sortByWeight(rest).reverse();
};

const windowConfigToWidgetContext = ({
  windowConfig,
  hostPageParams,
  preventDefaultOpening = false,
}: {
  windowConfig: WindowConfig;
  hostPageParams?: any;
  preventDefaultOpening?: boolean;
}): IWidgetContext => {
  const {
    accountId,
    apps,
    logo,
    style,
    positions,
    appearances,
    googleAnalytics4,
    featureFlags,
    tracking,
    account,
  } = windowConfig;
  const sortedApps = sortApps(apps);
  const pinnedApps = getPinnedApps(apps);

  const highlighted =
    pinnedApps.length !== 0
      ? (pinnedApps.find(({ hidden }) => hidden !== true) ?? sortedApps[0])
      : (apps.find(
          ({ highlighted, hidden }) => highlighted === true && hidden !== true,
        ) ?? sortedApps[0]);

  return {
    preventDefaultOpening,
    hostPageParams,
    googleAnalytics4,
    isDefaultConfig: windowConfig.isDefaultConfig ?? false,
    accountId,
    account,
    positions,
    appearances,
    logo,
    highlighted,
    styleConfig: style,
    apps: generateAppsMap([...pinnedApps, ...sortedApps]),
    featureFlags,
    tracking,
  };
};
export default windowConfigToWidgetContext;
