import React from 'react';
import type {
  DHApp,
  StyleConfig,
  Positions,
  Appearances,
  FeatureFlag,
  AccountFields,
} from '../../shared/types';

export type IWidgetContext = {
  preventDefaultOpening?: boolean;
  hostPageParams?: string;
  googleAnalytics4?: string;
  isDefaultConfig: boolean;
  accountId: string;
  account: AccountFields;
  positions: Positions;
  appearances: Appearances;
  logo: {
    src: string;
  };
  apps: Record<DHApp['slug'], DHApp>;
  highlighted: DHApp;
  styleConfig: StyleConfig;
  featureFlags: Array<FeatureFlag>;
  tracking?: {
    url: string;
    key: string;
  };
};

const WidgetContext = React.createContext<IWidgetContext | null>(null);

export default WidgetContext;
